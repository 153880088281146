<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席中金财富“AI与量化投资”圆桌会交流
              </p>
              <div class="fst-italic mb-2">2023年7月10日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中金财富</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/50/01-圆桌.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技受邀参与中金财富“高质量发展新征程”机构投资“浦江荟”活动，券商、管理人、专家学者、技术厂商等汇聚一堂，共同探讨新格局下的发展机遇。在“AI与量化投资”圆桌会交流上，与会嘉宾就AI在量化投资中的应用、发展趋势，以及面临的挑战和风险，展开了有益的前沿讨论。
                  </p>
                  <p>
                    近年来，量化投资发展迅速，AI应用日益广泛。在这样的背景下，中金公司研究所量化策略研究员古翔从宏观研究视角出发，并表示，“AI技术应用到量化投资模型，当前已有产品投入实践，从近两年的表现来看，效果可观，也是团队未来要重点布局的一个方向。”
                  </p>
                  <p>
                    非凸科技商务总监朱家辉从技术厂商的视角分享了AI应用落地情况，认为超额收益中有很重要的一部分，来源于AI技术对股价的预测。非凸科技主要运用机器学习、深度学习来做预测模型，在预测股价涨跌外的同时预测当前市场波动率，决定下单策略的激进程度，争取整体最优执行价格，从而帮助机构客户获得交易层面的超额收益。未来，非凸科技计划引入强化学习技术，将现有股价预测和下单决策整合成一个网络，实现端到端的决策。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/50/02-朱家辉.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    事实上，AI技术的出现，已经对量化行业产生了翻天覆地的变化，且深度赋能的趋势不可阻挡。传统的多因子线性模型非常拥挤，带来的超额收益也相对有限，而AI技术最大的贡献在于，可以对因子进行非线性组合，并运用模型带来与市场较低相关性的信号。近一年来，非凸科技市场占有率迅速提高，得益于多种AI模型的运用，既有机器学习、深度学习股价预测模型，也有模型间的多模型融合、组合优化等，这为非凸科技提供了强有力的绩效竞争力。”
                  </p>
                  <p>
                    华锐技术机构业务总监&ATP产品联席负责人葛云娟也站在技术厂商的角度分享了未来发展趋势，她表示，“越来越多的头部量化机构在AI领域做了较大的投入和准备，对行业来说，这可以满足量化机构更多元化需求；对个体来说，这意味着行业门槛将会更高，竞争更大；对投资人来说，这让管理人可以选择更靠谱的产品；对高频客户来说，这对低延迟的要求极其苛刻，需要持续的算力投入。相较国外，国内高频交易领域在低延迟方面的竞争，未来仍然是一个大趋势。”
                  </p>
                  <p>
                    中金财富产品与解决方案自营交易负责人李明华从券商交易服务视角分享了见解，他表示，“今年，中金财富除了在算法层面的布局较多之外，还在大力投入因子挖掘，应用大量AI前沿技术，软硬件相结合，为机构客户提供多方面、多层次、全生态的服务和支持。”
                  </p>
                  <p>
                    金戈量锐资产管理投资总监张相华和锐天投资创始人徐晓波，也分别从管理人视角分享了AI投入情况。张相华表示，“从目前来看，AI应用比较成功的领域集中在图像、语音、文字识别等，他们有一个明确的信息边界，可以定量的输入、输出，而当AI碰到金融市场上很多繁杂且微弱的东西时，可能就不会那么明确。对于传统的方式，线性模型有一个很直观的解释，在使用中能更好地把控风险。所以，我希望AI只是一个工序逻辑。”对此，徐晓波表示，“AI肯定不是量化的全部，但GPT的出现确实给了AI一个很大的想象空间，能够帮助我们解决很复杂的问题。就公司而言，我们有在持续布局和应用AI，但不是全部，保持偏中性的观点；就个人而言，我相信AI未来的发展空间非常大。”
                  </p>
                  <p>
                    然而，AI技术的应用，也会让量化投资面临诸多挑战和风险。朱家辉表示，“通过AI来做预测模型，可能存在的弊端之一是过拟合。非凸科技会在AI拟合过程中不断进行统计上的检验，多种样本内、样本外的交叉检验，以及模拟盘、实盘的多轮迭代，在各个环节防止过拟合情况的出现。葛云娟也从监管方面发表了一些看法，她表示，“从2021年至今，监管在量化投资领域受到的重视越来越多，同时也可能是存在风险较多的地方。无论是AI，还是量化交易，未来不仅要做好投资收益，还要让投资做得更长久、更健康、更安全。”
                  </p>
                  <p>
                    未来，非凸科技将紧跟时代的潮流和脉搏，不断优化，共同成长。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News50",
};
</script>

<style></style>
